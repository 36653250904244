import React, {CSSProperties} from 'react';

import './VerticalList.css';

interface VerticalListProps {
  children: React.ReactChild | React.ReactChild[]; // React.ReactNode
  className?: string;
  style?: CSSProperties;
}

const VerticalList: React.FC<VerticalListProps> = ({
  children,
  className,
  style,
}: VerticalListProps) => {
  return (
    <ul className={`vertical-list ${className}`} style={style}>
      {children}
    </ul>
  );
};

VerticalList.defaultProps = {
  className: '',
  style: undefined,
};

export default VerticalList;
