import React from 'react';
import {Link, useLocation, useRouteMatch} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import MenuHeader from './MenuHeader';
import LanguageSelector from '../../common/LanguageSelector/LanguageSelector';
import VerticalList from './VerticalList';
import VerticalListItem from './VerticalListItem';
import MenuMapLegend from './MenuMapLegend';

import './MenuModal.css';

interface MenuModalProps {
  setModalVisible: (arg: boolean) => void;
  modalVisible: boolean;
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
}

const MenuModal: React.FC<MenuModalProps> = ({
  setModalVisible,
  modalVisible,
  language,
  setLanguage,
}: MenuModalProps) => {
  const showHideClassName = modalVisible ? 'display-flex' : 'display-none';

  const location = useLocation();
  const {url} = useRouteMatch();

  const {t: translate} = useTranslation();

  return (
    <div className={`menu-modal ${showHideClassName}`}>
      <div>
        <nav>
          <VerticalList>
            <VerticalListItem className="no-border">
              <MenuHeader
                setModalVisible={setModalVisible}
                language={language}
              />
            </VerticalListItem>
            <VerticalListItem style={{height: '78px'}}>
              <LanguageSelector
                light
                language={language}
                setLanguage={setLanguage}
              />
            </VerticalListItem>
            <VerticalListItem>
              <Link
                onClick={() => setModalVisible(false)}
                // to="/map/?showRoute=true"
                to="/route"
                className="link-button">
                {translate('menuModal.route')}
              </Link>
            </VerticalListItem>
            <VerticalListItem>
              <Link
                onClick={() => {
                  setModalVisible(false);
                }}
                to={() => {
                  // if (location.pathname === '/map/?showRoute=true')
                  //   return '/map/museum-info?showRoute=true';
                  // return '/map/museum-info';
                  if (location.pathname.includes('route')) {
                    return '/route/museum-info';
                  }
                  return '/map/museum-info';
                }}
                className="link-button">
                {translate('menuModal.info')}
              </Link>
            </VerticalListItem>
            <VerticalListItem>
              <Link
                onClick={() => setModalVisible(false)}
                to="/map"
                className="link-button">
                {translate('menuModal.map')}
              </Link>
            </VerticalListItem>
          </VerticalList>
        </nav>
      </div>
      <MenuMapLegend className="push-down" />
    </div>
  );
};

export default MenuModal;
