import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Lightbox from 'react-image-lightbox';
import {useViewport} from '../../../hooks/useViewport';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import './PoiDetailPhotoGallery.css';
// helper methods
import {transformPoiImageSource} from '../../../util/helperMethods';
// types
import {CaptionedImage} from '../../../types';

/* eslint-disable react/jsx-props-no-spreading */

interface PoiDetailPhotoGalleryProps {
  images: CaptionedImage[];
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
}

const PoiDetailPhotoGallery: React.FC<PoiDetailPhotoGalleryProps> = ({
  images,
  isOpen,
  setIsOpen,
}: PoiDetailPhotoGalleryProps) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const {t: translate} = useTranslation();

  const windowWidthThreshold = 620;
  const windowWidth = useViewport().width;

  const generateKey = (pre: string) => {
    return `${pre}_${new Date().getTime()}`;
  };

  const handleImageClick = (
    index: number,
    image: {image: string; caption: string},
  ) => {
    setIsOpen(true);
    setPhotoIndex(index);
  };

  const addDefaultSrc = (event: any) => {
    event.target.src = '/assets/images/photos/3b-kipos.jpg'; // eslint-disable-line no-param-reassign
  };

  return (
    <div>
      {isOpen && windowWidth > windowWidthThreshold && (
        <Lightbox
          mainSrc={transformPoiImageSource(images[photoIndex].image)}
          nextSrc={transformPoiImageSource(
            images[(photoIndex + 1) % images.length].image,
          )}
          prevSrc={transformPoiImageSource(
            images[(photoIndex + images.length - 1) % images.length].image,
          )}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
          enableZoom={false}
        />
      )}
      <div className="poi-detail-photo-gallery">
        <h2>{translate('poiDetailModal.photoGalleryHeader')}</h2>
        <ul>
          {images.map((image, index) => {
            return (
              <li key={generateKey(index.toString())}>
                <button
                  type="button"
                  onClick={() => handleImageClick(index, image)}>
                  <img
                    className="photo-gallery-image"
                    src={transformPoiImageSource(image.image)}
                    onError={addDefaultSrc}
                    alt={image.caption}
                  />
                </button>
                <div className="photo-gallery-title">
                  <h4>{image.caption}</h4>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default PoiDetailPhotoGallery;
