import React from 'react';

import './DetailImage.css';

interface DetailImageProps {
  src: string;
  alt?: string;
}

const Image: React.FC<DetailImageProps> = ({src, alt}: DetailImageProps) => {
  return <img className="detail-image" src={src} alt={alt} />;
};

Image.defaultProps = {
  alt: 'Detail Image',
};

export default Image;
