// @ts-nocheck
import React, {useState, useRef, useEffect} from 'react';
import ReactPlayer from 'react-player';

import AudioPlayerControls from './AudioPlayerControls';

import './AudioPlayer.css';

const format = (seconds: number) => {
  if (Number.isNaN(seconds)) {
    return `00:00`;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, '0');
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`;
  }
  return `${mm.toString().padStart(2, '0')}:${ss}`;
};

let count = 0;

interface AudioPlayerProps {
  config: any;
  url: any;
  shouldPlay: boolean;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  url,
  config,
  shouldPlay,
}: AudioPlayerProps) => {
  const [playing, setPlaying] = useState(false);
  const [seeking, setSeeking] = useState(false);

  const [played, setPlayed] = useState(0);
  // const [duration, setDuration] = useState(0);

  const playerRef = useRef<typeof ReactPlayer>(null);
  const controlsRef = useRef<typeof AudioPlayerControls>(null);

  const [currentTime, setCurrentTime] = useState(
    playerRef && playerRef.current
      ? playerRef.current.getCurrentTime()
      : '00:00',
  );
  const durat =
    playerRef && playerRef.current ? playerRef.current.getDuration() : '00:00';
  const curTime =
    playerRef && playerRef.current ? playerRef.current.getCurrentTime() : 0;
  const totalDuration = format(durat);

  const [elapsedTime, setΕlapsedTime] = useState('00:00');

  const volume = 0.8;
  const controls = false;

  useEffect(() => {
    if (shouldPlay === false && playing) {
      setPlaying(false);
    }
  }, [shouldPlay, playing]);

  useEffect(() => {
    setΕlapsedTime(format(currentTime));
  }, [currentTime, elapsedTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(
        playerRef && playerRef.current
          ? playerRef.current.getCurrentTime()
          : '00:00',
      );
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    return function cleanup() {
      setPlaying(false);
    };
  }, []);

  useEffect(() => {
    setCurrentTime(
      playerRef && playerRef.current
        ? playerRef.current.getCurrentTime()
        : '00:00',
    );
  }, [playerRef, currentTime]);

  const handleProgress = (changeState: any) => {
    if (controlsRef.current) {
      setΕlapsedTime(elapsedTime);
      if (count > 3) {
        // controlsRef.current.style.visibility = 'hidden';
        count = 0;
      }
      // if (controlsRef.current.style.visibility === 'visible') {
      count += 1;
      // }

      if (!seeking) {
        setSeeking(changeState);
      }
    }
  };

  const handleSeekChange = (e: any, newValue: number) => {
    setPlayed(newValue / 100);
  };

  const handleSeekMouseUp = (e: any, newValue: number) => {
    if (playerRef.current) {
      console.log({value: e.target});
      setSeeking(false);
      playerRef.current.seekTo(newValue / 100, 'fraction');
    }
  };

  const handleDuration = (dur: number) => {
    setDuration(dur);
  };

  const handlePlayPause = () => {
    console.log('Play/Pause button pressed');
    if (shouldPlay) {
      setPlaying(!playing);
    }
  };

  const handleSeekMouseDown = (e: any) => {
    setSeeking(true);
  };

  return (
    <div className="audio-player-wrapper">
      <AudioPlayerControls
        ref={controlsRef}
        playing={playing}
        played={played}
        elapsedTime={elapsedTime}
        onPlayPause={handlePlayPause}
        handleSeekChange={handleSeekChange}
        currentTime={curTime}
        totalDuration={totalDuration}
        durat={durat}
        setCurrentTime={setCurrentTime}
        format={format}
      />
      <ReactPlayer
        ref={playerRef}
        className="react-player"
        url={url}
        config={config}
        width="100%"
        height="100%"
        playing={playing}
        onProgress={handleProgress}
        onEnded={() => {
          setPlaying(!playing);
        }}
        volume={volume}
        controls={controls}
        light={false}
        loop={false}
        progressInterval={100} // OnProgress callback speed is too large, which will cause the progress bar to move unsmoothly
      />
    </div>
  );
};

export default AudioPlayer;
