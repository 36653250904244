import React, {useRef, useEffect, useState, CSSProperties} from 'react';
import ReactDOM from 'react-dom';
import {useRouteMatch, useHistory, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import env from 'react-dotenv';
import mapboxgl, {LngLatBoundsLike} from 'mapbox-gl';
import useQuery from '../../hooks/useQuery';
// components
import PoiDetailModal from '../../components/modals/PoiDetailModal/PoiDetailModal';
import InfoModal from '../../components/modals/InfoModal/InfoModal';
import PoiTile from '../../components/features/Tour/PoiTile';
import Loader from '../../components/common/Loader/Loader';
// modules
import loadPOIs from '../../modules/loadPOIs';
import loadRoute from '../../modules/loadRoute';
import loadImageLayer from '../../modules/loadImageLayer';
// helper methods
import {getDataByLang, getFirstPoi} from '../../util/helperMethods';
// assets
import route_data from '../../assets/route_data.json';
import tour_el_data from '../../assets/tour_el_data.json';
import KapodistriasGardenMapImage from '../../assets/images/OrthoPhotoMosaic.jpg';
// types
import {Poi} from '../../types';
// styles
import './Tour.css';

// const mapboxAccessToken = env.MAPBOX_ACCESS_TOKEN;
const mapboxAccessToken =
  'pk.eyJ1Ijoia2FvbmlzIiwiYSI6ImNrcTk5bnUxcjBudDUyd212Z2g1bmtpanoifQ.ZXcojgwW-jNr4SiqUARwbw';

const Tour: React.FC = () => {
  const {t: translate, i18n: i18next} = useTranslation();
  const query = useQuery();
  const location = useLocation();
  const showRoute = !!location.pathname.includes('route');
  // const {id: selectedPoiId} = useParams<{id: string}>();
  const [poiDetailModalVisible, setPoiDetailModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const match = useRouteMatch();
  const history = useHistory();

  let matchPoiId = '';
  if (
    // @ts-ignore
    match.params.id !== 'museum-info' &&
    // @ts-ignore
    tour_el_data.find(item => item.id === match.params.id)
  ) {
    // @ts-ignore
    matchPoiId = match.params.id;
  }

  const [selectedPoi, setSelectedPoi] = useState(getFirstPoi(i18next.language));

  console.log(match.params);

  const [selectedPoiId, setSelectedPoiId] = useState(matchPoiId);

  /*  
    Calc tool https://www.keene.edu/campus/maps/tool/ square coordinates order
    19.8594005, 39.6299979 northeast
    19.8564491, 39.6301567 northwest
    19.8562726, 39.6276320 southwest
    19.8592044, 39.6275094 southeast

    19.8578344, 39.6288547 center
  */

  const mapImageCornerCoords = [
    [19.8564490763838, 39.6301566721903], // northwest given: [19.8564490763838, 39.6301566721903]  calced: 19.8564491, 39.6301567
    [19.859380936035, 39.6300340731318], // northeast given: [19.859380936035, 39.6300340731318] calced: 19.8594005, 39.6299979
    [19.8592043169147, 39.6275094105296], // southeast given: [19.8592043169147, 39.6275094105296]  calced: 19.8592044, 39.6275094
    [19.8562725643448, 39.6276320151953], //  southwest given: [19.8562725643448, 39.6276320151953] calced: 19.8562726, 39.6276320
  ];

  //  calced: 19.8578344, 39.6288547 original: 19.85936772, 39.6288389 new:
  const [lng, setLng] = useState(19.85791501); // old: 19.8578344 calced: 19.85791501
  const [lat, setLat] = useState(39.62889434); // old: 39.6288547 calced: 39.62889434
  const [zoom, setZoom] = useState(19);

  const [isMapLoading, setIsMapLoading] = useState(true);
  const [showHideStyle, setShowHideStyle] = useState<CSSProperties>({
    opacity: 0,
  });

  // Set bounds to Capodistrias Museum Garden area
  const bounds: LngLatBoundsLike = [
    [19.8568726, 39.628112], // southwest coordinates
    [19.8587005, 39.6298379], // northeast coordinates
  ];

  const getSquareCoordinatesFromMapPoint = (
    longtitude: number,
    latitude: number,
    distanceFromCenter: number, // In KM
  ) => {
    const dLatitude = distanceFromCenter / 111.044736; // KM to latitude degrees
    const dLongtitude = dLatitude / Math.cos(0.872664626); //

    const southLat = latitude - dLatitude; // (southernmost latitude)
    const northLat = latitude + dLatitude; // (northernmost latitude)
    const westLong = longtitude - dLongtitude; // (western longitude)
    const eastLong = longtitude + dLongtitude; // (eastern longitude)
    // [longtitude, latitude]
    // southwestern corner then clockwise (northwest, northeast, southeast)
    return [
      [westLong, northLat], // northwest
      [eastLong, northLat], // northeast
      [eastLong, southLat], // southeast
      [westLong, southLat], // southwest
    ];
  };

  const mapContainerRef = useRef(document.createElement('div'));
  const popUpRef = useRef(
    new mapboxgl.Popup({
      offset: 15,
      closeButton: false,
      closeOnClick: true,
      closeOnMove: true,
      maxWidth: 'none',
    }),
  );

  useEffect(() => {
    // @ts-ignore
    if (showRoute && poiDetailModalVisible && selectedPoiId) {
      history.push(`/route/${selectedPoiId}`);
    } else if (selectedPoiId && poiDetailModalVisible) {
      history.push(`/map/${selectedPoiId}`);
    }
  }, [poiDetailModalVisible, selectedPoiId, history, showRoute]);

  // initialize map when component mounts
  useEffect(() => {
    if (mapboxAccessToken) {
      mapboxgl.accessToken = mapboxAccessToken;
    }

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      // style: 'mapbox://styles/mapbox/satellite-v9',
      style: {
        version: 8,
        name: 'Empty',
        sources: {},
        layers: [
          {
            id: 'background',
            type: 'background',
            paint: {
              'background-color': 'hsl(47, 26%, 88%)',
            },
          },
        ],
        // id: 'empty',
      },
      center: [lng, lat],
      zoom,
      maxZoom: 21,
      minZoom: 16.5,
      maxBounds: bounds,
      attributionControl: false,
      bearing: 0,
    });

    // add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

    // Load POIs after map has loaded too
    map.on('load', () => {
      loadImageLayer(map, KapodistriasGardenMapImage, mapImageCornerCoords);

      loadPOIs(map, getDataByLang(i18next.language));

      if (showRoute) {
        loadRoute(
          map,
          route_data.map(point => [point.lng, point.lat]),
        );
      }
    });

    map.on('idle', function () {
      setIsMapLoading(false);
      setShowHideStyle({opacity: 1});
    });

    // add popup when user clicks a point - export this to a module
    getDataByLang(i18next.language).forEach(poi => {
      // @ts-ignore
      map.on('click', `layer-${poi.id}`, (event: any) => {
        if (typeof event !== 'undefined') {
          if (event.features.length) {
            const feature = event.features[0];

            const popupNode = document.createElement('div');

            ReactDOM.render(
              <PoiTile
                feature={feature}
                selectedPoiId={selectedPoiId}
                setSelectedPoiId={setSelectedPoiId}
                setSelectedPoi={setSelectedPoi}
                setPoiDetailModalVisible={setPoiDetailModalVisible}
                setInfoModalVisible={setInfoModalVisible}
                popUpRef={popUpRef}
              />,
              popupNode as Element,
            );
            // set popup on map
            popUpRef.current
              .setLngLat(feature.geometry.coordinates)
              .setDOMContent(popupNode as Node)
              .addTo(map);
          }
        }
      });
    });

    // clean up on unmount
    return () => map.remove();
    // @ts-ignore
  }, [showRoute]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isMapLoading && <Loader />}
      <div
        className="map-container"
        ref={mapContainerRef}
        style={showHideStyle}
      />
      <div id="popup-poitile" />
      <InfoModal
        infoModalVisible={infoModalVisible}
        setInfoModalVisible={setInfoModalVisible}
      />
      {selectedPoiId && (
        <PoiDetailModal
          selectedPoi={selectedPoi}
          setSelectedPoi={setSelectedPoi}
          // currentPoi={currentPoi}
          selectedPoiId={selectedPoiId}
          onClick={() => {}}
          setPoiDetailModalVisible={setPoiDetailModalVisible}
          poiDetailModalVisible={poiDetailModalVisible}
          currentPopUpRef={popUpRef.current}
        />
      )}
    </>
  );
};

export default Tour;
