import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Dotdotdot from 'react-dotdotdot';
import {PoiTileArrow} from '../../../assets/icons';
import {findPoiById} from '../../../util/helperMethods';
import {Poi} from '../../../types';
import './PoiTile.css';

interface PoiTileProps {
  feature: {
    properties: {
      title: string;
      id: string;
      text: string;
    };
  };
  selectedPoiId: string;
  setPoiDetailModalVisible: (arg: boolean) => void;
  setInfoModalVisible: (arg: boolean) => void;
  popUpRef: any;
  setSelectedPoi: (arg: Poi) => void;
  setSelectedPoiId: (arg: string) => void;
}

const PoiTile: React.FC<PoiTileProps> = ({
  feature,
  selectedPoiId,
  setSelectedPoiId,
  setSelectedPoi,
  setPoiDetailModalVisible,
  setInfoModalVisible,
  popUpRef,
}: PoiTileProps) => {
  const {t: translate, i18n: i18next} = useTranslation();
  const [clampLines, setClampLines] = useState(4);

  // workaround for the dotdotdot not working bug
  useEffect(() => {
    const timeout = setTimeout(() => {
      setClampLines(5);
    }, 1);
    return function cleanup() {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className="poi-tile-container">
      <Dotdotdot clamp={clampLines - 2}>
        <h3 className="poi-tile-title">{feature.properties.title}</h3>
      </Dotdotdot>
      <Dotdotdot clamp={clampLines}>
        <p className="poi-tile-tagline">{feature.properties.text}</p>
      </Dotdotdot>
      <button
        type="button"
        onClick={() => {
          setSelectedPoiId(feature.properties.id);
          setSelectedPoi(
            findPoiById(feature.properties.id, i18next.language) as Poi,
          );
          setInfoModalVisible(false);
          setPoiDetailModalVisible(true);
          popUpRef.current.remove();
        }}
        className="poi-tile-arrow">
        <img src={PoiTileArrow} alt="show poi details button" />
      </button>
    </div>
  );
};

export default PoiTile;
