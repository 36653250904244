import React from 'react';

import './DetailDescription.css';

interface DetailDescriptionProps {
  children: string;
}

const DetailDescription: React.FC<DetailDescriptionProps> = ({
  children,
}: DetailDescriptionProps) => {
  return <p className="detail-description">{children}</p>;
};

export default DetailDescription;
